import React, { useContext } from "react";
import {
    Box,
    Button, Grid, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import { Launch } from "@material-ui/icons/index";
import { BrandContext } from "../../../contexts/BrandContext";
import { CardContainer, LoadingBox, formatAddressToString } from "../../UniversalComponents";

import { getAddressFields } from "./Handler";

import { ValuationsContext } from "./ValuationsContext";
import CustomMenuList from "../../CustomMenuList";
import BasicInputField from "../../form/BasicInputField";
import { Link, useNavigate } from "react-router-dom";


export default function Results(props){

    
    const {
        resFormik,
        resFormFields
    } = props;

    const context = useContext(ValuationsContext);
    const brandContext = useContext(BrandContext);
    
    const searchArr = [];
    const fields = getAddressFields(context,{});
    const keys = Object.keys(fields);
    const values = Object.values(fields);
    
    for (let i = 0; i < keys.length; i += 1) {
        if(props.searchParams.get(keys[i])){

            let flagLabel = props.searchParams.get(keys[i]);
            if('type' in values[i]){
                if(values[i].type === 'select'){
                    try{
                        for(let j=0; i<values[i].options.length; j+=1){
                            if(`${values[i].options[j].value}` === `${props.searchParams.get(keys[i])}`){
                                flagLabel = values[i].options[j].label;
                                break;
                            }
                        }
                    }
                    catch(e){
                        console.warn(e);
                    }
                }
                if(values[i].type === 'checkbox') {
                    if(props.searchParams.get(keys[i])){
                        flagLabel = '';
                    }
                }
                if(values[i].chip === false) {
                    flagLabel = '';
                }
            }
            if(flagLabel){
                searchArr.push({
                    val: flagLabel,
                    flag: keys[i]
                });
            }

        }
    }

    const {
        textContent:{
            valuationsTableRefNumber='',
            valuationsTableValuers='',
            valuationsTableValuationDate='',
            valuationsTableClientName='',
            valuationsTableAddress='',
            valuationsTableValuationType='',
            valuationsTableTransactionType='',
            applyFiltersButton='',
            openButton='',
            filterBy='',
            clearFiltersButton=''
        },
        textContent
    } = brandContext;

    const navigate = useNavigate();

    const navigateToRes = (id) => {
        navigate(`/valuation/${id}`);
    }
    const getFirstCharacter = (inputString) => inputString.length > 0 ? inputString[0].toUpperCase() : '';

    return (
        <>
            <CardContainer className="resultsCard">
                {context.isLoading ?
                    <LoadingBox/>
                    :
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <BasicInputField formik={props.formik} formFields={props.formFields} fieldName="textSearch" variant="outlined" className="cardSearch"/>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h3">{filterBy}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <BasicInputField formik={props.formik} formFields={props.formFields} fieldName="selectValuer" variant="outlined" className="cardSearch" sx={{width:'100%'}}/>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box sx={{display:'flex'}}>
                                    <BasicInputField formik={props.formik} formFields={props.formFields} fieldName="useDateRange" variant="outlined" className="cardSearch" sx={{width:'100%'}}/>
                                    <BasicInputField formik={props.formik} formFields={props.formFields} fieldName="fromDate" variant="outlined" className="cardSearch" sx={{width:'100%', marginRight:2}} disabled={props.formik.values.useDateRange ? false : true} />
                                    <BasicInputField formik={props.formik} formFields={props.formFields} fieldName="toDate" variant="outlined" className="cardSearch" sx={{width:'100%'}} disabled={props.formik.values.useDateRange ? false : true}/>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <BasicInputField formik={props.formik} formFields={props.formFields} fieldName="valuationType" variant="outlined" className="cardSearch" sx={{width:'100%'}}/>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <BasicInputField formik={props.formik} formFields={props.formFields} fieldName="transactionType" variant="outlined" className="cardSearch" sx={{width:'100%'}}/>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Box sx={{ minWidth: 300 }}>
                                        
                                    <Button
                                        sx={{float:'right', marginTop:3, }}
                                        variant="contained"
                                        className="filterButton"
                                        onClick={()=>{props.formik.handleSubmit();}}
                                        
                                    >
                                        {applyFiltersButton}
                                    </Button>
                                    <Button
                                        sx={{float:'right', marginTop:3, marginRight:3}}
                                        variant="contained"
                                        className="filterButton"
                                        onClick={()=>{
                                            props.formik.resetForm();
                                            context.resetSearch()
                                        }}
                                        
                                    >
                                        {clearFiltersButton}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                }
            
                {(!context.isLoading) &&
                <>

                        <TableContainer className="bigWideTable">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{valuationsTableRefNumber}</TableCell>
                                        <TableCell>{valuationsTableValuers}</TableCell>
                                        <TableCell>{valuationsTableValuationDate}</TableCell>
                                        <TableCell>{valuationsTableClientName}</TableCell>
                                        <TableCell>{valuationsTableAddress}</TableCell>
                                        <TableCell>{valuationsTableValuationType}</TableCell>
                                        <TableCell>{valuationsTableTransactionType}</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {context.results.map((row, index) => {

                                        const {
                                            id='',
                                            client,
                                            case:cCase,
                                            isCancelled=false,
                                            estateAgencyData:{
                                                valuationType=null,
                                                proposedTransactionType=null
                                            },
                                            events=[],
                                            matterUserRoles=[]
                                        } = row;
                                          
                                        let clientName = '';
                                        if(client){
                                            try{
                                                if('primaryContact' in client){
                                                    ({primaryContact:{fullName:clientName}} = client);
                                                }
                                            }
                                            catch(e){
                                                // cWarn(e)
                                            }
                                            
                                        }


                                        let valuerInitials = '';

                                        for(let i=0; i<matterUserRoles.length; i+=1){
                                            const{
                                                bdUser:{
                                                    firstName,
                                                    lastName,
                                                    userFirmsLinks=[]
                                                },
                                                roleType:{
                                                    id:roleTypeId=0
                                                }
                                            } = matterUserRoles[i];
                                            if(roleTypeId === 8){
                                                valuerInitials = `${getFirstCharacter(firstName)}${getFirstCharacter(lastName)}`;
                                                break;
                                            }
                                        }


                                        let valuationDateFormatted = '';
                                        if(events.length > 0){
                                            const {startTime:valuationDate} = events[0];
                                            const dateObj = new Date(valuationDate);
                                            const options = { year: 'numeric', month: 'short', day: 'numeric' , hour: 'numeric', minute: 'numeric'};
                                            valuationDateFormatted = new Intl.DateTimeFormat('en-GB', options).format(dateObj);
                                        }
                                        const formattedAddress = formatAddressToString(cCase);
                                        let valuationTypeString='';
                                        if(valuationType){
                                            ({textKey:valuationTypeString} = valuationType);
                                        }
                                        let transactionTypeString='';
                                        if(proposedTransactionType){
                                            ({textKey:transactionTypeString} = proposedTransactionType);
                                        }

                                        return(
                                        <TableRow  className={`stripyRow ${isCancelled ? 'bookingCancelled' : ''}`} key={index}>
                                            <TableCell>
                                                <Button component={Link} to={`/valuation/${id}`}>{id}</Button>
                                            </TableCell>
                                            <TableCell className="linkCell" onClick={() => {navigateToRes(id)}}>
                                                {valuerInitials}
                                            </TableCell>
                                            <TableCell className="linkCell" onClick={() => {navigateToRes(id)}}>
                                                {valuationDateFormatted}
                                            </TableCell>
                                            <TableCell className="linkCell" onClick={() => {navigateToRes(id)}}>
                                                {clientName}
                                            </TableCell>
                                            <TableCell className="linkCell" onClick={() => {navigateToRes(id)}}>
                                                {formattedAddress}
                                            </TableCell>
                                            <TableCell className="linkCell" onClick={() => {navigateToRes(id)}}>
                                                {textContent[valuationTypeString]}
                                            </TableCell>
                                            <TableCell className="linkCell" onClick={() => {navigateToRes(id)}}>
                                                {textContent[transactionTypeString]}
                                            </TableCell>
                                            <TableCell>
                                            <BasicInputField sx={{marginRight:0}} formik={resFormik} formFields={resFormFields} fieldName="resCheckBoxes" value={id} onChange={()=>{
                                                console.log('task selected')
                                            }}/>
                                                <CustomMenuList
                                                    size="medium"
                                                    id={row.id}
                                                    context={context}
                                                    options={[
                                                    {
                                                        text: openButton,
                                                        iconComponent: <Launch/>,
                                                        to:`/valuation/${id}`,
                                                        branded: true
                                                    }
                                                ]}/>
                                            </TableCell>
                                        </TableRow>);
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 30, 60, 100,500,1000]}
                            component="div"
                            count={context.resTotal}
                            rowsPerPage={context.searchConfig.r}
                            page={context.searchConfig.p}
                            onPageChange={(e,p) => {
                                context.setResPage(p);
                            }}
                            onRowsPerPageChange={(r) => {
                                context.setResSize(r.target.value);
                            }}
                        />

                </>
                

                }
                </CardContainer>
        </>
    );
}