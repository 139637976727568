import React, { useContext } from 'react';
import { BrandContext } from '../../../contexts/BrandContext';
import { Container } from '@mui/material';
import Page from '../../Page';
import MattersHeader from '../../ReportViews/Matters/MattersHeader';
import MatterDetailsContextProvider from './MatterDetailsContext';
import MatterDetailsInner from './MatterDetailsInner';
import { useParams } from 'react-router-dom';
import WidgetsContextProvider from '../pcdashboard/WidgetsContext';


export default function MatterDetails(props) {

    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            privateClient:pageTitle=''
        }
    } = brandContext;

    const {matterId} = useParams();
    
    return (
        <>
            <Page title={pageTitle}>
                <Container maxWidth="xl">
                    <MatterDetailsContextProvider matterId={matterId} >
                        <WidgetsContextProvider matterId={matterId} >
                            <MattersHeader title={pageTitle}/>
                            <MatterDetailsInner/>
                        </WidgetsContextProvider>    
                    </MatterDetailsContextProvider>
                </Container>
            </Page>
        </>
    );
}

