//REACT
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logoImg from '../assets/logo-dark-l.svg';

//MUI COMPONENTS

import { BrandContext } from '../contexts/BrandContext';
import useAuth from '../hooks/useAuth';

import { useLocation } from 'react-router-dom';
import { navConfig } from '../NavConfig';
import { Box, AppBar, Toolbar, Button, Container, IconButton, Menu, MenuItem, Drawer, List, ListItemButton, ListItemText, Collapse, styled, alpha, InputBase, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Divider } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import SearchIcon from '@mui/icons-material/Search';
import axios from '../utils/axios';
import { cWarn } from './CustomLogger';
import { LoadingBox } from './UniversalComponents';

//Custom Contexts


const Navigation = (props) => {


    const {logout} = useAuth();

    const brandContext = useContext(BrandContext);

    const {
        textContent={},
    } = brandContext;
    
    
    const {
        textContent:{
            logoutButton=''
        },
        brand:{
            mode,
            setup:{
                logoTag=''
            }
        }
    } = brandContext;

    

    const location = useLocation();
    const {pathname} = location;

    const drawerWidth = 240;

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    const { window } = props;

    
    const MainNavButton = (props) => {
        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleMenu = (event) => {
            setAnchorEl(event.currentTarget);
          };
        
          const handleClose = () => {
            setAnchorEl(null);
          };

        const{
            link:{
                path,
                label,
                subNav=[]
            }
        } = props;
        const isActive = path === pathname;

        if(subNav.length > 0){
            return (
                <>
                    <Button  className={`linkTitle ${(isActive || anchorEl) && 'linkTitleActive'}`} onClick={handleMenu} style={{ display: { xs: 'none', sm: 'block' } }}>
                        {textContent[label]}
                    </Button>
                    <Menu
                        id="menu-appbar"
                        className="mainNavSubMenu"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {subNav.map((subLink,index)=>{
                            const{
                                path,
                                label,
                                nav=true
                            } = subLink;
                            return (
                                (nav !== false) ? (<MenuItem key={index} component={Link} to={path}>{textContent[label]}</MenuItem>) : null
                            );
                        })}
                    </Menu>
                </>
            );
        }
        else{
            return(
                <Button className={`linkTitle ${isActive && 'linkTitleActive'}`} component={Link} to={path} style={{ display: { xs: 'none', sm: 'block' } }}>
                    {textContent[label]}
                </Button>
            );
        }
    }

    const MobileNavButton = (props) => {

        


          const [open, setOpen] = React.useState(false);

          const handleClick = (e) => {
            e.stopPropagation();
            // console.log('this has been cliecked');
            setOpen(!open);
          };

        const{
            link:{
                path,
                label,
                subNav=[]
            }
        } = props;
        const isActive = path === pathname;

        if(subNav.length > 0){
            return (
                <>
                    <ListItemButton  className={`linkTitle ${isActive && 'linkTitleActive'}`} onClick={handleClick}>
                        <ListItemText primary={textContent[label]} />
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List>     
                        {subNav.map((subLink,index)=>{
                                const{
                                    path,
                                    label,
                                    nav=true
                                } = subLink;
                                return (
                                    (nav !== false) ? (
                                    <ListItemButton key={index} className={`linkSub ${isActive && 'linkTitleActive'}`} to={path}>
                                        <ListItemText primary={textContent[label]} />
                                    </ListItemButton>) : null
                                );
                        })}
                        </List>
                    </Collapse>
                </>
            );
        }
        else{
            return(
                <>
                    <ListItemButton  className={`linkTitle ${isActive && 'linkTitleActive'}`} to={path}>
                        <ListItemText primary={textContent[label]} />
                    </ListItemButton>
                </>
            );
        }
    }

    const UserProfile = (props) => {

        const [anchorEl, setAnchorEl] = React.useState(null);
      
        const handleMenu = (event) => {
          setAnchorEl(event.currentTarget);
        };
      
        const handleClose = () => {
          setAnchorEl(null);
        };

        return(
            <Box className="headerProfileButton">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                className="headerProfileButtonMenu"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={logout}>{logoutButton}</MenuItem>
              </Menu>
            </Box>
        );
    }

    const container = window !== undefined ? () => window().document.body : undefined;
    

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(1),
          width: 'auto',
        },
      }));
      
      const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }));
      
      const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        width: '100%',
        '& .MuiInputBase-input': {
          padding: theme.spacing(1, 1, 1, 0),
          // vertical padding + font size from searchIcon
          paddingLeft: `calc(1em + ${theme.spacing(4)})`,
          transition: theme.transitions.create('width'),
          [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
              width: '20ch',
            },
          },
        },
      }));

      const showSearchModal = (term) => {
        setSetSearchGo({
            showDialogue:true,
            term
        });
      }

    const [searchGo,setSetSearchGo] = useState({
        showDialogue:false,
        term:''
    });
    const {
        showDialogue,
        term
    } = searchGo;
    console.log(searchGo);    

    return (
        <>
        <AppBar className="bigNavBar" position="fixed">
            <Container>
                <Toolbar className="tBar">
                    <Box className="logoContainer" sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Link className="" to="/"> <img width="110" height="" src={logoImg} alt=""/>
                        <span className="logoHover">{logoTag}</span>
                        </Link>
                    </Box>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    
                        {navConfig[mode].map((link,index)=>(
                             (link.nav !== false) ? (
                                <MainNavButton link={link} key={index}/>
                            ) : null
                        ))}
                    <Box flexGrow={1} />
                    <Search>
                        <SearchIconWrapper>
                        <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            onKeyUp = {(e) => {
                                if (e.key === 'Enter') {
                                    showSearchModal(e.currentTarget.value);
                                }
                            }}
                            />
                    </Search>
                    <UserProfile/>
                </Toolbar>
            </Container>
        </AppBar>
        <Box component="nav">
            <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            className="navDrawer"
            >
            <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} >
                    <Box className="logoContainer">
                        <Link className="" to="/"> <img width="110" height="" src={logoImg} alt=""/>
                        <span className="logoHover">EA</span>
                        </Link>
                    </Box>
                
                    <Divider />
                    <List>
                    {navConfig[mode].map((link, index) => (
                        (link.nav !== false) ? (
                            <MobileNavButton link={link} key={index}/>
                        ) : null
                    ))}
                    </List>
                </Box>
            </Drawer>
        </Box>
            {showDialogue && 
                <SearchDialogue
                    showDialogue={showDialogue}
                    setShowDialogue={setSetSearchGo}
                    term={term}
                />
            }
        </>
    );
};

const SearchDialogue = (props) => {
    const brandContext = useContext(BrandContext);

    const {
        showDialogue,
        setShowDialogue,
        term=''
    } = props;
    const {
        textContent:{
            cancel='',
            searchTitle:title=''
        }
    } = brandContext;

    const [isLoaded,setIsLoaded] = useState(false);
    const [results,setResults] = useState([]);

    /* eslint-disable */
    useEffect(() => {
        if(!isLoaded){
            loadData();
        }
    },[isLoaded]);
    /* eslint-enable */

    const loadData = () => {
        const apiPath = `${process.env.REACT_APP_API_URL}/basicsearch`;

        axios.post(apiPath,{
            term
        },{
            timeout: 300000,
            withCredentials:true
        }).then(res => {

            const {
                data
            } = res;
            setResults(data);
            setIsLoaded(true);
        }).catch(err => {
            cWarn(err); 
        });
    }
    const navigate = useNavigate();

    const navigateToRes = (id) => {
        navigate(`/matter/${id}`);
    }
   

    return (
        <Dialog open={showDialogue} onClose={()=>{setShowDialogue({showDialogue:false,term:''})}}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {isLoaded ? 
                <Box sx={{width:'300px'}}>
                    {results.map((result,index)=>{
                        
                        const {
                            value:id=0,
                            label
                        } = result;

                        return (
                        <Box key-={index}>
                            <Typography sx={{cursor:'pointer', marginBottom:'5px'}} onClick={()=>{
                                navigateToRes(id);
                                setShowDialogue({showDialogue:false,term:''})

                            }}>
                                {label}
                            </Typography>
                        </Box>
                        );
                    })}
                </Box>
                :
                <LoadingBox/>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{setShowDialogue({showDialogue:false,term:''})}}>{cancel}</Button>
            </DialogActions>
        </Dialog>
    );
}


export default Navigation;