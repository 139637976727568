import { Alert, Box, Button, Grid, Stack, Typography } from "@mui/material";
import BasicInputField from "../form/BasicInputField";
import { Person } from "@material-ui/icons";
import { Fragment, useContext, useEffect, useState } from "react";
import { BrandContext } from "../../contexts/BrandContext";
import { selectAddressAndMoveOn } from "../Utilities/AddressAutoCompletion";
import { initialValues } from "../UniversalComponents";
import axios from '../../utils/axios';
import { cWarn } from "../CustomLogger";



export default function ContactCreate(props){
    
    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            primaryClientDetailsTitle='',
            addClientButton='',
            foundMatchingUser=''
        }
    } = brandContext;

    const {
        formikProps,
        fieldConfig:{
            contactFields,
            groupFields
        },
        noDob = false
    } = props;

    const [totalContacts,setContactTotal] = useState(1);
    const [emailDisbable,setEmailDisable] = useState({});    
    const [hasUserFound,setHasUserFound] = useState({});

    const emailUpdate = (e,index) => {
        //check for the email address in the system and deactivate rows until checked
        setEmailDisable({
            [index]:true
        });
        setHasUserFound({
            [index]:null
        });
        const apiPath = `${process.env.REACT_APP_API_URL}/contactcheck/`;
        axios.post(apiPath,{
            email:e.currentTarget.value
        },{
            timeout: 300000,
            withCredentials:true
        }).then((res) => {
            setEmailDisable({[index]:false});
            const { 
                data:{
                    contact=null
                }
            } = res;
            if(contact){
                setHasUserFound({
                    [index]:contact
                });
                console.log(contact);
                const map = {
                    cTitle:'cTitle',
                    firstName:'firstName',
                    middleName:'middleName',
                    lastName:'lastName',
                    tel1:'tel1',
                    mobile:'mobile',
                    houseName:'houseNo',
                    houseNumber:'houseNo',
                    postcode:'postcode',
                    street:'streetName',
                    town:'town',
                }
                const keys = Object.keys(map);
                const values = Object.values(map);
                for(let i=0; i<values.length; i+=1){
                    if(contact[keys[i]]){
                        formikProps.setFieldValue(`c_${values[i]}_${index}`,contact[keys[i]]);
                    }
                }
            }
        }).catch(err => {
            cWarn(err);
        });
    }

    let formFields = groupFields;
    
    for(let i=0; i<totalContacts; i+=1){
        const modContactFields = {};
        
        const keysArray = Object.keys(contactFields);
        const valuesArray = Object.values(contactFields);
        for(let j=-0; j<keysArray.length; j+=1){
            modContactFields[`${keysArray[j]}_${i}`] = valuesArray[j];
        }
        modContactFields[`c_startTyping_${i}`].optionTemplate = (option) => `${option.address}`;

        formFields = {...formFields,...modContactFields};
    }    

    const contactsIndicis = Array.from({ length: totalContacts }, (_, index) => index);

    const addContactFields = () => {
        formikProps.initialValues[`c_sameAsPrimary_${totalContacts}`] = true;
        /*
        const matchFields = [
            'houseNo',
            'streetName',
            'addrL2',
            'town',
            'postcode'
        ];

        for(let i=0; i<matchFields.length; i+=1){
            const fieldName = matchFields[i];
            formikProps.initialValues[`c_${fieldName}_${totalContacts}`] = formikProps.values[`c_${fieldName}_0`];
        }
        */
        setContactTotal(totalContacts+1);
    }
    

    console.log(formikProps.values);
    return (
        <Box>
            <Typography variant="h2">{primaryClientDetailsTitle}</Typography>
            <Box sx={{marginTop:5}}>
                <Stack alignItems="left" sx={{marginBottom:5}}>
                        <Grid container >
                            <Grid item xs={12} md={12} className="inputHolder">
                                <BasicInputField formik={formikProps} formFields={formFields} fieldName="g_onBehalfCompany" variant="outlined" />
                                {
                                    formikProps.values.g_onBehalfCompany &&
                                    <BasicInputField className="cardInput" formik={formikProps} formFields={formFields} fieldName="g_companyName" variant="outlined" />    
                                }
                            </Grid>
                            {
                                contactsIndicis.map((index) =>{


                                    const setAddress = (props,formik) => {
        
                                        const map = {
                                            houseName:'houseNo',
                                            houseNumber:'houseNo',
                                            postcode:'postcode',
                                            street:'streetName',
                                            town:'town',
                                        }
                                        const keys = Object.keys(map);
                                        const values = Object.values(map);
                                        for(let i=0; i<values.length; i+=1){
                                            if(props[keys[i]]){
                                                formik.setFieldValue(`c_${values[i]}_${index}`,props[keys[i]]);
                                            }
                                        }
                                        console.log(formik.values);
                                    }

                                    const emailDisbableSetting = {};
                                    if(index in emailDisbable){
                                        if(emailDisbable[index]){
                                            emailDisbableSetting.disabled = true;
                                        }
                                    }

                                    let userFound = null;
                                    if(index in hasUserFound){
                                        if(hasUserFound[index]){
                                            userFound = hasUserFound[index];
                                        }
                                    }

                                    console.log(hasUserFound);

                                    return (
                                    <Fragment key={index}>
                                        {userFound && 
                                            <Box>
                                                <Alert icon={<Person fontSize="inherit" />} severity="success">
                                                    {foundMatchingUser}
                                                </Alert>
                                            </Box>
                                        }
                                        {index > 0 &&
                                        <>
                                            <Grid item xs={12} md={12} sx={{paddingTop:10}} className="inputHolder">
                                                <Typography variant="h3">Client {index+1} details</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12} className="inputHolder">
                                                <BasicInputField formik={formikProps} formFields={formFields} fieldName={`c_sameAsPrimary_${index}`} variant="outlined" />  
                                            </Grid>
                                        </>
                                        }

                                        <Grid item xs={12} md={12} className="inputHolder">
                                            <BasicInputField className="cardInput" formik={formikProps} formFields={formFields} fieldName={`c_title_${index}`} variant="outlined" {...emailDisbableSetting}/>  
                                        </Grid>
                                        <Grid item xs={12} md={12} className="inputHolder">
                                            <BasicInputField className="cardInput" formik={formikProps} formFields={formFields} fieldName={`c_firstName_${index}`} variant="outlined" {...emailDisbableSetting}/>  
                                        </Grid>
                                        <Grid item xs={12} md={12} className="inputHolder">
                                            <BasicInputField className="cardInput" formik={formikProps} formFields={formFields} fieldName={`c_knownAs_${index}`} variant="outlined" {...emailDisbableSetting}/>  
                                        </Grid>
                                        <Grid item xs={12} md={12} className="inputHolder">
                                            <BasicInputField className="cardInput" formik={formikProps} formFields={formFields} fieldName={`c_middleName_${index}`} variant="outlined" {...emailDisbableSetting}/>  
                                        </Grid>
                                        <Grid item xs={12} md={12} className="inputHolder">
                                            <BasicInputField className="cardInput" formik={formikProps} formFields={formFields} fieldName={`c_lastName_${index}`} variant="outlined" {...emailDisbableSetting}/>  
                                        </Grid>
                                        <Grid item xs={12} md={12} className="inputHolder">
                                            <BasicInputField className="cardInput" formik={formikProps} formFields={formFields} fieldName={`c_tel_${index}`} variant="outlined" {...emailDisbableSetting}/>  
                                        </Grid>
                                        <Grid item xs={12} md={12} className="inputHolder">
                                            <BasicInputField className="cardInput" formik={formikProps} formFields={formFields} fieldName={`c_email_${index}`} variant="outlined" onChange={(e)=>{emailUpdate(e,index)}}/>  
                                        </Grid>
                                        {(index === 0 || !formikProps.values[`c_sameAsPrimary_${index}`]) &&
                                        <>
                                            <Grid item xs={12} md={12} className="inputHolder">
                                                <BasicInputField className="cardInput" formik={formikProps} formFields={formFields} fieldName={`c_startTyping_${index}`} variant="outlined" onChange={(value)=>{selectAddressAndMoveOn(value,formikProps,setAddress)}}  />  
                                            </Grid>
                                            <Grid item xs={12} md={12} className="inputHolder">
                                                <BasicInputField className="cardInput" formik={formikProps} formFields={formFields} fieldName={`c_houseNo_${index}`} variant="outlined" {...emailDisbableSetting}/>  
                                            </Grid>
                                            <Grid item xs={12} md={12} className="inputHolder">
                                                <BasicInputField className="cardInput" formik={formikProps} formFields={formFields} fieldName={`c_streetName_${index}`} variant="outlined" {...emailDisbableSetting}/>  
                                            </Grid>
                                            <Grid item xs={12} md={12} className="inputHolder">
                                                <BasicInputField className="cardInput" formik={formikProps} formFields={formFields} fieldName={`c_addrL2_${index}`} variant="outlined" {...emailDisbableSetting}/>  
                                            </Grid>
                                            <Grid item xs={12} md={12} className="inputHolder">
                                                <BasicInputField className="cardInput" formik={formikProps} formFields={formFields} fieldName={`c_town_${index}`} variant="outlined" {...emailDisbableSetting}/>  
                                            </Grid>
                                            <Grid item xs={12} md={12} className="inputHolder">
                                                <BasicInputField className="cardInput" formik={formikProps} formFields={formFields} fieldName={`c_postcode_${index}`} variant="outlined" {...emailDisbableSetting}/>  
                                            </Grid>
                                        </>
                                        }
                                        {!noDob &&
                                            <Grid item xs={12} md={12} className="inputHolder">
                                                <BasicInputField className="cardInput" formik={formikProps} formFields={formFields} fieldName={`c_dob_${index}`} variant="outlined" />  
                                            </Grid>
                                        }
                                    </Fragment>
                                    );
                                })
                            }
                        </Grid>
                </Stack>
                <Button
                        className="roundButton"
                        onClick={()=>{addContactFields()}}
                        startIcon={<Person/>}
                    >
                        {addClientButton}
                </Button>
            </Box>
        </Box>
    );

}