import { Description, Email, FileCopy, FormatListNumbered, Group, House } from '@material-ui/icons';
import { lazy } from 'react';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import HouseIcon from '@mui/icons-material/House';

export const widgetMap = {
    DocumentStore: {
        title:'widgetTitleDocumentStore',
        icon:<Description/>,
        component: lazy(() => import('../../Widgets/DocStore/DocumentStore'))
    },
    MarketingMaterials: {
        title:'widgetTitleMarketingMaterials',
        icon:<Description/>,
        component: lazy(() => import('../../Widgets/DocStore/MarketingMaterials'))
    },
    Checklist: {
        title:'widgetTitleChecklist',
        icon:<FormatListNumbered/>,
        component: lazy(() => import('../../Widgets/Checklists/Checklist'))
    },
    SortingOffice: {
        title:'widgetTitleSortingOffice',
        icon:<FileCopy/>,
        component: lazy(() => import('../../Widgets/SortingOffice'))
    },
    Parties: {
        title:'widgetTitleParties',
        icon:<Group/>,
        component: lazy(() => import('../../Widgets/Parties'))
    },
    PrimaryClientDetails: {
        title:'primaryClientDetailsTitle',
        component: lazy(() => import('../../Widgets/BlueWidgets/Clients/PrimaryClientDetails'))
    },
    OtherClientDetails: {
        title:'otherClientDetailsTitle',
        component: lazy(() => import('../../Widgets/BlueWidgets/Clients/OtherClientDetails'))
    },
    AssignedTeam: {
        title:'assignedTeamTitle',
        component: lazy(() => import('../../Widgets/BlueWidgets/AssignedTeam/AssignedTeam'))
    },
    ClientNotes: {
        title:'widgetsClientNotes',
        icon:<NewspaperIcon/>,
        component: lazy(() => import('../../Widgets/ClientNotes'))
    },
    HouseDoctor: {
        title:'widgetsHouseDoctorTips',
        icon:<AutoFixHighIcon/>,
        component: lazy(() => import('../../Widgets/EA/HouseDoctorTips'))
    },
    ValuerNotes: {
        title:'widgetsValuerNotes',
        icon:<NoteAltIcon/>,
        component: lazy(() => import('../../Widgets/EA/ValuerNotes'))
    },
    PropertyInformation: {
        title:'widgetsPropertyInformation',
        icon:<House/>,
        component: lazy(() => import('../../Widgets/EA/PropertyInformation'))
    },
    PropertyResearch: {
        title:'widgetsPropertyResearch',
        icon:<LaptopMacIcon/>,
        component: lazy(() => import('../../Widgets/PropertyResearch/PResearch'))
    },
    BudgetPlanner: {
        title:'widgetTitleBudgetPlanner',
        icon:<CurrencyPoundIcon/>,
        component: lazy(() => import('../../Widgets/BudgetPlanner/BudgetPlanner'))
    },
    DigiFile: {
        title:'tabTitlesDigitalFile',
        icon:<FileCopy/>,
        component: lazy(() => import('../../Widgets/DigiFile/DigiFile'))
    },
    ActivityLog: {
        title:'widgetActivityLog',
        icon:<FormatListBulletedIcon/>,
        component: lazy(() => import('../../Widgets/EmptyWidget'))
    },
    PropertyStats: {
        title:'propertyStatsTitle',
        icon:<StackedBarChartIcon/>,
        component: lazy(() => import('../../Widgets/PropertyResearch/PropertyStats'))
    },
    Comparables: {
        title:'ComparableTitle',
        icon:<HouseIcon/>,
        component: lazy(() => import('../../Widgets/PropertyResearch/Comparables'))
    },
};

export const leftWidgets = [
    'PrimaryClientDetails','OtherClientDetails','AssignedTeam'
];

export const marketDataWidgets = [
    'PropertyStats','Comparables'
];


export const tabs = [
    {
        urlKey:'clientdetails',
        nameKey:'tabClientDetails',
        widgets:['ClientNotes','HouseDoctor','ValuerNotes','PropertyInformation','MarketingMaterials','DocumentStore','SortingOffice']
    },
    {
        urlKey:'prospectdetails',
        nameKey:'tabPropsectDetails',
        widgets:['PropertyResearch','BudgetPlanner']
    },
    {
        urlKey:'digitalfile',
        nameKey:'tabDigitalFile',
        widgets:['DigiFile']
    },
    {
        urlKey:'general',
        nameKey:'tabGeneral',
        widgets:['ActivityLog']
    }
];


