import React, { useContext, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { BrandContext } from '../../contexts/BrandContext';
import axios from '../../utils/axios';
import { cWarn } from '../CustomLogger';



export default function ArchiveMatterDialogue(props){
    
    const {
        context,
        matterId
    } = props;
    const flag = 'showArchiveMatterDialogue';
    const {
        [flag]:showState=false,
        hideDialogue,
        accessScope
    } = context;

    const brandContext = useContext(BrandContext);        

        const  {
            textContent:{
                archiveMatterTitle:title = '',
                buttonArchive='',
                archiveCheckText='',
                cancel=''
            }
        } = brandContext;

        const [isLoaded,setIsLoaded] = useState(false);
        const [formFields,setFormFields] = useState([]);



        const runDialogueAction = (values) => {
            const apiPath = `${process.env.REACT_APP_API_URL}/archivematter/${matterId}`;
            axios.post(apiPath,values,{
                timeout: 300000,
                withCredentials:true
            }).then(() => {
                hideDialogue(flag);
                window.location.href='/';
            }).catch(err => {
            cWarn(err); 
            });
        }
    
    return (
        <Dialog open={showState} onClose={()=>{hideDialogue(flag)}} fullWidth className="brandedDialogue" >
            <Box sx={{minWidth:600}}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>{archiveCheckText}</DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={()=>{hideDialogue(flag)}}>{cancel}</Button> 
                    <Button variant="outlined" onClick={runDialogueAction}>{buttonArchive}</Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
}

