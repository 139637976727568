import { Box, Divider, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BrandContext } from "../../contexts/BrandContext";
import { getDefaultMatterName } from "../ReportViews/Matters/MatterUtilities";


export default function RelatedTransactions(props){

    const {
        context
    } = props;

    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            relatedTransaction='',
        }
    } = brandContext;

    const {
        relatedMatters
    } = context;
    
    const navigate = useNavigate();

    const navigateToRes = (id) => {
        
        window.location.href=`/matter/${id}`
    }

    

    return (
        <Paper sx={{padding:2}}>
            <Typography variant="h3">{relatedTransaction}</Typography>
            {relatedMatters.map((matter,index)=>{
                const {
                    id,
                    matterData,
                    matterUserRoles=[]
                } = matter;
                              
                
                let dueDate = '';
                let matterName = '';
                if(matterData){
                    ({dueDate,matterName} = matterData);
                } 
                if(matterName === '' || matterName === null){
                    matterName = getDefaultMatterName(matter);
                }
                let dueDateFormatted = '';
                if(dueDate){
                    const dateObj = new Date(dueDate);
                    const options = { year: 'numeric', month: 'short', day: 'numeric' };
                    dueDateFormatted = new Intl.DateTimeFormat('en-GB', options).format(dateObj);
                }

                const getFirstCharacter = (inputString) => inputString.length > 0 ? inputString[0].toUpperCase() : '';

                return (
                    <Box sx={{cursor:'pointer', paddingTop:'5px', paddingBottom:'5px'}} key={index} onClick={() => {navigateToRes(id)}}>
                        <Box><Typography variant="body1Bold">{matterName}</Typography></Box>
                        <Box>
                            {matterUserRoles.map((userRole,index)=>{
                                const{
                                    bdUser:{
                                        firstName,
                                        lastName,
                                        userFirmsLinks=[]
                                    }
                                } = userRole;
                                let initials = '';
                                if(userFirmsLinks.length > 0){
                                    const {
                                        customInitials=''
                                    } = userFirmsLinks[0];
                                    if(customInitials !== ''){
                                        initials = customInitials;
                                    }
                                }
                                if(!initials){
                                    initials = `${getFirstCharacter(firstName)}${getFirstCharacter(lastName)}`;
                                }
                                const isLast = index === matterUserRoles.length-1;
                                return (
                                    <Typography key={index} component="span" variant="smallText">
                                        {initials}{!isLast && '/'}
                                    </Typography>
                                )

                            })}
                        </Box>
                        <Box>
                            <Typography variant="smallText">{dueDateFormatted}</Typography>
                        </Box>
                        <Divider/>
                    </Box>
                );
            })}
        </Paper>
    );

}