import { Replay } from '@material-ui/icons';
import React, { createContext } from 'react';

export const WidgetContext = createContext();

class WidgetContextProvider extends React.Component {
    constructor(props){
        super(props);

        const {
            activeTab = '',
            widgetKey = ''
        } = props;

        this.widgetKey = widgetKey;

        this.defaultTools = 
            {
                text: 'Reload',
                iconComponent: <Replay/>,
                action:()=>{this.reload()},
                branded: true
            };
        
        
        const localStorageConfig = this.getWidgetLocalStorage();
        const {
            isExpanded=false
        } = localStorageConfig;
        

        const obj = {
            tools:this.defaultTools,
            isLoaded:false,
            isExpanded,
            widgetKey          
        };
        this.state = obj;
    }

    componentDidUpdate(prevProps) {
        // Check if activeTab has changed
        if (this.props.activeTab !== prevProps.activeTab) {
            // Update isLoaded to false
            this.setState({ isLoaded: false });
        }
    }

    setTools(tools,replace=false){
        if(replace){
            this.setState({tools});
        }
        else{
            this.setState((prevState) => ({
                tools: [this.defaultTools, ...tools]
            }));
        }
    }

    setIsLoaded(){
        this.setState({isLoaded:true});
    }

    reload(){
        this.setState({isLoaded:false});
    }

    toggleExpansion(){
        
        const {
            isExpanded
        } = this.state;
        const newState = !isExpanded;
        this.updateLocalStorage({
            isExpanded:newState
        })

        this.setState((prevState) => {
            return{ 
             isExpanded:!prevState.isExpanded ,
             isLoaded:false
            }
         })
    }

    getWidgetKey(){
        const {
            widgetKey='',
            activeTab
        } = this.props;
        let widgetHash = '';
        if(activeTab){
            const{
                nameKey=''
            } = activeTab;
            widgetHash = `${widgetKey}_${nameKey}`;
        }
        return widgetHash;   
    }

    updateLocalStorage(updateData){
        const widgetKey = this.getWidgetKey();
        const widgetConfig = localStorage.getItem('widgetConfig');
        let existingStorage = {};
        let fullWidgetConfig = {};
        if(widgetConfig){
            fullWidgetConfig = JSON.parse(widgetConfig)
            if(fullWidgetConfig){
                if(widgetKey in fullWidgetConfig){
                    existingStorage = fullWidgetConfig[widgetKey];
                }
            }
        }
        const newStorage = {...existingStorage,...updateData};
        fullWidgetConfig = {...fullWidgetConfig,...{
            [widgetKey]:newStorage
        }}
        localStorage.setItem('widgetConfig',JSON.stringify(fullWidgetConfig));
    }

    getWidgetLocalStorage(){
        const widgetKey = this.getWidgetKey();
        const widgetConfig = localStorage.getItem('widgetConfig');
        if(widgetConfig){
            const widgetConfigData = JSON.parse(widgetConfig)
            if(widgetConfigData){
                if(widgetKey in widgetConfigData){
                    return widgetConfigData[widgetKey];
                }
            }
        }
        return {};
    }

    render(){
        return (
            <WidgetContext.Provider value={{
                ...this.state,
                setState: this.setState.bind(this),
                setTools: this.setTools.bind(this),
                setIsLoaded: this.setIsLoaded.bind(this),
                toggleExpansion: this.toggleExpansion.bind(this),
                reload: this.reload.bind(this)
            }}>
                {this.props.children}
            </WidgetContext.Provider>
        );
    }

    simpleClone(obj){
        JSON.parse(JSON.stringify(obj));
    }
}
export default WidgetContextProvider;