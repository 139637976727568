import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Box, Button } from "@mui/material";
import { Alert } from '@material-ui/lab';
// import { GoogleLogin } from "react-google-login";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import logoImg from '../assets/logo-dark-o.svg';

import { loginRequest, msalConfig } from "../authConfig";

import useAuth from '../hooks/useAuth';
import { BrandContext } from "../contexts/BrandContext";
import { FaApple, FaArrowCircleRight, FaFacebook, FaGoogle, FaMicrosoft } from 'react-icons/fa';

export default function BDAuth(){

    
    /* eslint-disable */
    /* eslint-enable */
    
    const {loginWithAzure } = useAuth();
    const brandContext = useContext(BrandContext);
    /*
    const loginWithGoogle = (res) =>{
        const apiPath = `${process.env.REACT_APP_API_URL}/connect/googlecode`;
        axios.post(apiPath,{
            gToken : res.getAuthResponse().id_token
        },{
            timeout: 30000,
            withCredentials:true
        }).then(res => {
            loginWithGoogles(res.data);
        }).catch(error => {
            // // console.log(error.response.data.msg)
            setLoginFailure(true);
        });
        
    };
    */

    const loginWithMicrosoft = (token) =>{
        const apiPath = `${process.env.REACT_APP_API_URL}/connect/azurecode`;
        axios.post(apiPath,{
            gToken : token
        },{
            timeout: 9000,
            withCredentials:true
        }).then(res => {
            // console.log('l;ooing in with azuer here');
            loginWithAzure(res.data);
        }).catch(error => {
            // console.log(error.response.data.msg)
            setLoginFailure(true);
        });;

        /* eslint-disable */
        /* eslint-enable */
    };



    const msalInstance = new PublicClientApplication(msalConfig);
    const [loginFailure,setLoginFailure] = useState(false)

    const {
        brand:{
            setup:{
                logoTag=''
            }
        }
    } = brandContext;

    function MsLogin (){
        const { instance } = useMsal();
        const handleLogin = (loginType) => {
            if (loginType === "popup") {
                instance.loginPopup(loginRequest).catch(e => {
                    // console.log(e);
                }).then(res => {
                    // console.log(res);
                    loginWithMicrosoft(res.accessToken);

                });
            } else if (loginType === "redirect") {
                instance.loginRedirect(loginRequest).catch(e => {
                    // console.log(e);
                });
            }
        }

        /*
        const GoogleButton = (<GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_APP_ID}
            buttonText="Login"
            onSuccess={loginWithGoogle}
            onFailure={onFailure}
            render={renderProps => (



                <Button 
                    variant="contained" 
                    className="loginButton"
                    startIcon={<FaGoogle/>}
                    endIcon={<FaArrowCircleRight/>}
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                >
                    Login with Google
                </Button>

            )}
            cookiePolicy='single_host_origin'
            style={{ marginTop: '100px' }}
            isSignedIn={false}
        />);
        */

        return (
        
        <Button 
            variant="contained" 
            className="loginButton"
            startIcon={<FaMicrosoft/>}
            endIcon={<FaArrowCircleRight/>}
            onClick={() => handleLogin('popup')}
        >
            Login with Microsoft
        </Button>
        
        
        );

    }

    return (
        <>
           <div className="login-box-inner" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '50px' }}>
                    <div style={{ position: 'relative' }}>
                    <img width="200" height="" src={logoImg} alt="" />
                    <div className="login-tab small darkBlue" style={{ top: '36px', right: '-20px', position: 'absolute', fontSize: '21px', fontWeight: '600', textTransform:'uppercase' }}>{logoTag}</div>
                    </div>

                    <h2 className="login-title" style={{ marginBottom: '40px' }}>
                    Log in to your account
                    </h2>
                
                    <MsalProvider instance={msalInstance}><MsLogin/></MsalProvider>

                  
                    <Button 
                        variant="contained" 
                        className="loginButton"
                        startIcon={<FaGoogle/>}
                        endIcon={<FaArrowCircleRight/>}

                    >
                        Login with Google
                    </Button>
            
          
                    <Button 
                        variant="contained" 
                        className="loginButton"
                        startIcon={<FaApple/>}
                        endIcon={<FaArrowCircleRight/>}
                    >
                        Login with Apple
                    </Button>

                    <Button 
                        variant="contained" 
                        className="loginButton"
                        startIcon={<FaFacebook/>}
                        endIcon={<FaArrowCircleRight/>}
                    >
                        Login with Facebook
                    </Button>
                    {loginFailure &&
                    <Box mt={3}>
                        <Alert severity="error">{brandContext.textContent.loginFailureText}</Alert>
                    </Box>
                    }
            </div>
            
        </>

    );

    /* eslint-disable */


}



/* eslint-enable */