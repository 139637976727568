import React, { useContext } from 'react';
import { BrandContext } from '../../../contexts/BrandContext';
import { Container } from '@mui/material';
import Page from '../../Page';
import ValuationDetailsContextProvider from './ValuationDetailsContext';
import ValuationsHeader from '../../ReportViews/Valuations/ValuationsHeader';
import ValuationDetailsInner from './ValuationDetailsInner';
import { useParams } from 'react-router-dom';


export default function ValuationDetails() {

    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            valuationDetailTitle:pageTitle=''
        }
    } = brandContext;

    const {valuationId} = useParams();

    return (
        <>
            <Page title={pageTitle}>
                <Container maxWidth="xl">
                    <ValuationDetailsContextProvider matterId={valuationId}>
                        <ValuationsHeader/>
                        <ValuationDetailsInner/>
                    </ValuationDetailsContextProvider>
                </Container>
            </Page>
        </>
    );
}

