import React, { createContext } from 'react';
import { Archive, Description, Email } from '@material-ui/icons';
import axiosInstance from '../../../utils/axios';



export const WidgetsContext = createContext();

class WidgetsContextProvider extends React.Component {

    constructor(props){
        super(props);

        const {
            matterId,
        } = props;

        const obj = {
            isLoading: true,
            details:{},
            basePath:`matter/${matterId}/`,
            accessScope:2,
            matterId,
            showCreateLetterDialogue:false,
            showCreateDocumentDialogue:false,
            digiMessageId:false,
            msgMode:'compose',
            tools:[
                {
                    iconComponent:<Email/>,
                    textKey:'sendEmail',
                    action: () => {
                        this.showCompileDialogue();
                    },
                },
                {
                    iconComponent:<Description/>,
                    textKey:'createNewLetter',
                    action: () => {
                        this.showDialogue('showCreateLetterDialogue');
                    },
                },
                {
                    iconComponent:<Description/>,
                    textKey:'createNewDocument',
                    action: () => {
                        this.showDialogue('showCreateDocumentDialogue');
                    },
                },
                {
                    textKey: 'buttonArchive',
                    iconComponent: <Archive/>,
                    action: () => {
                        this.showDialogue('showArchiveMatterDialogue');
                    },
                    branded: true
                },
            ]
        };



        this.matterId = matterId;
        this.state={...obj};
    }

    componentDidMount() {
        this.loadData();
    }
    
    hideDialogue(flag=''){
        this.setState({
            [flag]:false
        });
    }
    showDialogue(flag=''){
        console.log(`showing dialog: ${flag}`);
        this.setState({
            [flag]:true
        });
    }

    showReplyDialogue(msgId){
        this.setState({
            digiMessageId:msgId,
            msgMode:'reply',
            showSendEmailDialogue:true
        });
    }
    showForwardDialogue(msgId){
        this.setState({
            digiMessageId:msgId,
            msgMode:'forward',
            showSendEmailDialogue:true
        });
    }
    showCompileDialogue(msgId){
        this.setState({
            digiMessageId:0,
            msgMode:'compose',
            showSendEmailDialogue:true
        });
    }

    loadData(){
        this.setState({
            isLoading:false
        });
    }


    nudge(key) {
        let newUpdate = 1;
        if (key in this.state) {
            newUpdate = this.state[key] + 1;
        }
        this.setState({
            [key]: newUpdate 
        });
        console.log(`'${key}' nudging to ${newUpdate}`);
    }

    hasBeenNudged(key,ref){
        const {current:prevStateUpdate} = ref;
        let newUpdate = 0;
        if (key in this.state) {
            newUpdate = this.state[key];
        }
        
        let hasUpdated = false;  
        if (prevStateUpdate !== undefined && prevStateUpdate !== newUpdate) {
            hasUpdated = true;
        }
        console.log(`Checking '${key}' - ${newUpdate ? 'has been nudged' : 'not nudged'}`);
        return hasUpdated;
    }

    render(){
        return (
            <WidgetsContext.Provider value={{
                ...this.state,
                loadData: this.loadData.bind(this),
                setState: this.setState.bind(this),
                hideDialogue:this.hideDialogue.bind(this),
                showDialogue:this.showDialogue.bind(this),
                showReplyDialogue:this.showReplyDialogue.bind(this),
                showForwardDialogue:this.showForwardDialogue.bind(this),
                showCompileDialogue:this.showCompileDialogue.bind(this),            
                nudge:this.nudge.bind(this),
                hasBeenNudged:this.hasBeenNudged.bind(this)
            }}>
                {this.props.children}
            </WidgetsContext.Provider>
        );
    }


}
export default WidgetsContextProvider;