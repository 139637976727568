import React, { useContext, useEffect, useState } from "react";
import { ValuationsContext } from "./ValuationsContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { Box, Button, Container, Link, Typography } from "@mui/material";
import CustomMenuList from "../../CustomMenuList";
import { Archive, Delete, FolderOpen, MoreHoriz, PictureAsPdf } from "@material-ui/icons";
import { ValuationDetailsContext } from "../../pages/valuations/ValuationDetailsContext";
import { ProfileContext } from "../../../contexts/ProfileContext";



export default function ValuationsHeader(props){

    const context = useContext(ValuationsContext);
    const brandContext = useContext(BrandContext);
    const detailsContext = useContext(ValuationDetailsContext);
    const {
        pageTitle=''
    }= props;

    const {
        textContent:{
            dbNavNewValuation='',
            buttonDelete='',
            buttonArchive='',
            buttonExportToPdf='',
            buttonOpenInNewTab=''
        }, 
        textContent={}
    } = brandContext;

    const profileContext = useContext(ProfileContext);
    const {
        profile:{
            userFirmsLinks=[]
        }
    } = profileContext;
    console.log(profileContext);
    let isAdmin = false;
    if(userFirmsLinks.length > 0){
        const {accountAdmin=0} = userFirmsLinks[0];
        if(accountAdmin === '1'){
            isAdmin = true;
        }
    }


    const defaultTools = [
        {
            text: buttonOpenInNewTab,
            iconComponent: <FolderOpen/>,
            action: () => {
                // console.log('open details dialogue');
            },
            branded: true
        },
        {
            text: buttonExportToPdf,
            iconComponent: <PictureAsPdf/>,
            action: () => {
                // console.log('open details dialogue');
            },
            branded: true
        },
    ];
    if(isAdmin){
        defaultTools.push(
            {
                text: buttonDelete,
                iconComponent: <Delete/>,
                action: (id,context) => {
                    context.setShowDeleteDialogue(true);
                },
                branded: true
            }
        );
    }

    let detailsTools = [];
    if(detailsContext){
        ({tools:detailsTools=[]} = detailsContext);
    }
    console.log(detailsContext);

    const tools = [...defaultTools,...detailsTools];

    for(let i=0;i<tools.length;i+=1){
        if('textKey' in tools[i]){
            tools[i]['text'] = textContent[tools[i]['textKey']];
        }
    }

    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
        // Check if the page has been scrolled
        const scrolled = window.scrollY > 0;

        // Update the state based on the scroll position
        setHasScrolled(scrolled);
        };

        // Attach scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Remove event listener on component unmount
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    let borderStyle = {};
    if(hasScrolled){
        borderStyle = {
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)'
        }
    }

    return (
        <Box className="fixedHeaderContainer">
            <Box className="fixerBox" sx={borderStyle}>
                <Container maxWidth="xl"  >
                    <Box className="pageHeader" sx={{marginBottom:10,display: 'flex'}}>
                        <Typography variant="h1" sx={{width:'100%'}}>
                            {pageTitle}
                        </Typography>
                        <Button style={{flexShrink:0, marginBottom:0, marginRight:20}} className="roundButton blueButton" href="/valuations/newvaluation" component={Link} to="/valuations/newvaluation">
                            {dbNavNewValuation}
                        </Button>
                        <CustomMenuList sx={{flexShrink:0}}
                            size="medium"
                            id="title_bar"
                            context={context}
                            customButton = {<Button style={{minWidth:0}} variant="outlined"><MoreHoriz/></Button>}
                            options={tools}
                        />
                    </Box>
                </Container>
            </Box>
        </Box>
);

}