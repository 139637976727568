import { Description, FileCopy, FormatListNumbered, Group, Storage } from '@material-ui/icons';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { lazy } from 'react';


export const widgetMap = {
    DocumentStore: {
        title:'widgetTitleDocumentStore',
        icon:<Description/>,
        component: lazy(() => import('../../Widgets/DocStore/DocumentStore'))
    },
    ClientNotes: {
        title:'widgetsClientNotes',
        icon:<NewspaperIcon/>,
        component: lazy(() => import('../../Widgets/ClientNotes'))
    },
    MatterNotes: {
        title:'widgetsMatterNotes',
        icon:<NewspaperIcon/>,
        component: lazy(() => import('../../Widgets/MatterNotes'))
    },
    SortingOffice: {
        title:'widgetTitleSortingOffice',
        icon:<FileCopy/>,
        component: lazy(() => import('../../Widgets/SortingOffice'))
    },
    DigiFile: {
        title:'tabTitlesDigitalFile',
        icon:<FileCopy/>,
        component: lazy(() => import('../../Widgets/DigiFile/DigiFile'))
    },
    Parties: {
        title:'widgetTitleParties',
        icon:<Group/>,
        component: lazy(() => import('../../Widgets/Parties'))
    },
    PrimaryClientDetails: {
        title:'primaryClientDetailsTitle',
        component: lazy(() => import('../../Widgets/BlueWidgets/Clients/PrimaryClientDetails'))
    },
    OtherClientDetails: {
        title:'otherClientDetailsTitle',
        component: lazy(() => import('../../Widgets/BlueWidgets/Clients/OtherClientDetails'))
    },
    AssignedTeam: {
        title:'assignedTeamTitle',
        component: lazy(() => import('../../Widgets/BlueWidgets/AssignedTeam/AssignedTeam'))
    },
    MatterDetails:{
        title:'tabMatterDetails',
        icon:<Storage/>,
        component: lazy(() => import('../../Widgets/PC/MatterDetails'))
    },
    ContactDetails:{
        title:'contactDetails',
        icon:<Group/>,
        component: lazy(() => import('../../Widgets/BlueWidgets/Clients/ContactDetails'))
    },
    ContactMatters:{
        title:'contactQuoteMatters',
        icon:<Group/>,
        component: lazy(() => import('../../Widgets/ClientMatters'))
    },
};

export const leftWidgets = [
    'ContactDetails'
];


export const tabs = [
    {
        urlKey:'matters',
        nameKey:'contactQuoteMatters',
        widgets:['ContactMatters']
    },
];


