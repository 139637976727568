import * as React from 'react';
import {useContext} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import throttle from 'lodash/throttle';
import axios from "../../utils/axios";

import { BrandContext } from '../../contexts/BrandContext';
import { Chip } from '@mui/material';

export default function ComboBox(props) {

    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const brandContext = useContext(BrandContext);


    const {
        textContent
    } = brandContext;

    const {
        fieldName,
        formFields,
        formik:{
            values,
            errors
        }
    } = props;

    const defaultField = {
        submitOnEnter: false
    };
    const runningField = {defaultField, ...formFields[fieldName]};

    const {
        options,
        useCms=true,
        multiple=true,
        label
    } = runningField;

    const extendProps = {...props};
    const filterFields = ['formik','formFields','fieldName','onChange'];
    filterFields.forEach(key => delete extendProps[key]);

    let fieldlabel = label;
    if(useCms){
        fieldlabel = textContent[runningField.label];
    }

    const {onChange:additionalOnChange} = props;

    let optionTemplate = (option) => {
      if(option){
          const {label=''} = option
          return label;
      }
      return ''
    };

    if('optionTemplate' in props.formFields[props.fieldName]){
      optionTemplate = props.formFields[props.fieldName].optionTemplate;
    }


    return (
      <Autocomplete
        multiple={multiple}
        id="tags-filled"
        options={options}
        defaultValue={values[props.fieldName]}
        {...extendProps}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={optionTemplate(option)} {...getTagProps({ index })} />
          ))
        }
        onChange={(e,newValue) => {
            props.formik.setFieldValue(fieldName, newValue)
            if(typeof additionalOnChange === 'function'){
              console.log(newValue);
                additionalOnChange(newValue)
            }
            
        }}
        getOptionLabel = {option => optionTemplate(option)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={fieldlabel}
            placeholder=""
            {...extendProps}
          />
        )}
      />
  );
}
