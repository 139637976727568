import { Description, FileCopy, FormatListNumbered, Group, Storage } from '@material-ui/icons';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { lazy } from 'react';


export const widgetMap = {
    DocumentStore: {
        title:'widgetTitleDocumentStore',
        icon:<Description/>,
        component: lazy(() => import('../../Widgets/DocStore/DocumentStore'))
    },
    Checklist: {
        title:'widgetTitleChecklist',
        icon:<FormatListNumbered/>,
        component: lazy(() => import('../../Widgets/Checklists/Checklist'))
    },
    ClientNotes: {
        title:'widgetsClientNotes',
        icon:<NewspaperIcon/>,
        component: lazy(() => import('../../Widgets/ClientNotes'))
    },
    MatterNotes: {
        title:'widgetsMatterNotes',
        icon:<NewspaperIcon/>,
        component: lazy(() => import('../../Widgets/MatterNotes'))
    },
    SortingOffice: {
        title:'widgetTitleSortingOffice',
        icon:<FileCopy/>,
        component: lazy(() => import('../../Widgets/SortingOffice'))
    },
    DigiFile: {
        title:'tabTitlesDigitalFile',
        icon:<FileCopy/>,
        component: lazy(() => import('../../Widgets/DigiFile/DigiFile'))
    },
    Parties: {
        title:'widgetTitleParties',
        icon:<Group/>,
        component: lazy(() => import('../../Widgets/Parties'))
    },
    PrimaryClientDetails: {
        title:'primaryClientDetailsTitle',
        component: lazy(() => import('../../Widgets/BlueWidgets/Clients/PrimaryClientDetails'))
    },
    OtherClientDetails: {
        title:'otherClientDetailsTitle',
        component: lazy(() => import('../../Widgets/BlueWidgets/Clients/OtherClientDetails'))
    },
    AssignedTeam: {
        title:'assignedTeamTitle',
        component: lazy(() => import('../../Widgets/BlueWidgets/AssignedTeam/AssignedTeam'))
    },
    MatterDetails:{
        title:'tabMatterDetails',
        icon:<Storage/>,
        component: lazy(() => import('../../Widgets/PC/MatterDetails'))
    },
    MatterTasks:{
        title:'widgetTitleMatterTasks',
        icon:<Storage/>,
        component: lazy(() => import('../../Widgets/Tasks/MatterTasks'))
    },
};

export const leftWidgets = [
    'PrimaryClientDetails','OtherClientDetails','AssignedTeam'
];


export const tabs = [
    {
        urlKey:'clientdetails',
        nameKey:'tabClientDetails',
        widgets:['ClientNotes','DocumentStore','SortingOffice','Parties']
    },
    {
        urlKey:'matterdetails',
        nameKey:'tabMatterDetails',
        widgets:['MatterNotes','DocumentStore','SortingOffice','MatterDetails','Checklist','MatterTasks']
    },
    {
        urlKey:'digitalfile',
        nameKey:'tabDigitalFile',
        widgets:['DigiFile']
    },
    {
        urlKey:'general',
        nameKey:'tabGeneral',
        widgets:['DocumentStore','SortingOffice']
    }
];


