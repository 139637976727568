import React, { useContext, useState } from "react";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import { FormGroup } from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import enGB from 'date-fns/locale/en-GB';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { BrandContext } from "../../contexts/BrandContext";

import FormAutoComplete from "./FormAutoComplete";
import FormAutoComplete2 from "./AutoComplete2";
import FormAutoComplete3 from "./AutoComplete3";
import dayjs from "dayjs";
import 'dayjs/locale/en-gb';
import FileUpload from "./FileUpload";
import BasicTextField from "./BasicTextField";
import { cLog, cWarn } from "../CustomLogger";
import BasicSelect from "./BasicSelect";
import TinyMce from "./TinyMce";
import TextAreaBig from "./TextAreaBig";
import BasicCheckBox from "./BasicCheckBox";


export default function BasicInputField(props){

    const brandContext = useContext(BrandContext);

    let initialValue = '';
    if(props.fieldName in props.formik.values){
        initialValue = props.formik.values[props.fieldName];
    }
    const [myValue,setMyValue] = useState(initialValue);
    const useStyles = makeStyles(theme => ({
        helperText:{
            display:'none'
        },
        dateField: {
            width:'100%',
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: theme.palette.primary.main,
                },
            }
        },

    }));

    const classes = useStyles();

    const extendProps = {...props};
    const filterFields = ['formik','formFields','fieldName','onChange'];
    filterFields.forEach(key => delete extendProps[key]);

    const {onChange:additionalOnChange} = props;
    
    if(props.fieldName in props.formFields) {

        const type = ('type' in props.formFields[props.fieldName] ? props.formFields[props.fieldName].type : 'text');
        const defaultField = {
            submitOnEnter: false
        };
        const runningField = {defaultField, ...props.formFields[props.fieldName]};

        

        return (
            <>
                 {type === 'text' &&
                <BasicTextField
                    {...props}
                />
                }
                {type === 'textSearch' &&
                <TextField
                    autoFocus
                    margin="dense"
                    id={props.fieldName}
                    value={myValue}
                    onChange={(e) => {
                        setMyValue(e.currentTarget.value);
                    }}
                    onBlur={props.formik.handleChange}
                    error={Boolean(props.formik.touched[props.fieldName] && props.formik.errors[props.fieldName])}
                    helperText={props.formik.errors[props.fieldName]}
                    label={props.fieldName in props.formFields ? brandContext.textContent[props.formFields[props.fieldName].label] : ''}
                    onKeyUp = {(e) => {
                        if(runningField.submitOnEnter){
                            if (e.key === 'Enter') {
                                props.formik.setFieldValue(props.fieldName,myValue);
                                runningField.submitOnEnter(props.values);
                            }
                        }
                    }}
                    
                    fullWidth
                    variant="standard"
                />
                }
                {type === 'currency' &&
                <TextField
                    autoFocus
                    margin="dense"
                    id={props.fieldName}
                    value={props.formik.values[props.fieldName]}
                    onChange={(e) => {
                        props.formik.setFieldValue(props.fieldName, e.currentTarget.value.toString().replace(/[^0-9.,]/g, ''));
                    }}
                    error={Boolean(props.formik.touched[props.fieldName] && props.formik.errors[props.fieldName])}
                    helperText={props.formik.errors[props.fieldName]}
                    label={props.fieldName in props.formFields ? brandContext.textContent[props.formFields[props.fieldName].label] : ''}
                    onKeyUp = {(e) => {
                        if(runningField.submitOnEnter){
                            if (e.key === 'Enter') {
                                runningField.submitOnEnter(props.values);
                            }
                        }
                    }}
                    InputProps={{
                        className: '',
                        startAdornment: <InputAdornment
                            position="start">&pound;</InputAdornment>,
                    }}
                    fullWidth
                    variant="standard"
                />
                }
                {type === 'textarea' &&
                <TextField
                    
                    multiline
                    rows={5}

                    margin="dense"
                    id={props.fieldName}
                    value={props.formik.values[props.fieldName]}
                    onChange={props.formik.handleChange}
                    error={Boolean(props.formik.touched[props.fieldName] && props.formik.errors[props.fieldName])}
                    helperText={props.formik.errors[props.fieldName]}
                    label={props.fieldName in props.formFields ? brandContext.textContent[props.formFields[props.fieldName].label] : ''}
                    fullWidth
                    variant="standard"
                    {...extendProps}
                />
                }
                {type === 'textareabig' &&
                <TextAreaBig
                    {...props}
                />
                }
                {type === 'richtextarea' &&
                <TinyMce
                    {...props}
                />
                }
                {type === 'select' &&
                   <BasicSelect {...props}/>
                }
                {type === 'typeahead' &&
                <FormAutoComplete {...props}/>
                }
                {type === 'autocomplete' &&
                <FormAutoComplete2 {...props}/>
                }
                {type === 'autocomplete3' &&
                <FormAutoComplete3 {...props}/>
                }
                {type === 'datePicker' &&
                <LocalizationProvider dateAdapter={AdapterDayjs}  adapterLocale="en-gb">
                    <DatePicker
                        variant="outlined"
                        fullWidth
                        id={props.fieldName}
                        label={props.fieldName in props.formFields ? brandContext.textContent[props.formFields[props.fieldName].label] : ''}
                        defaultValue={dayjs(props.formik.values[props.fieldName])}
                        onChange={(e) => {
                            props.formik.setFieldValue(props.fieldName, e);
                        }}
                        {...extendProps}
                    />
                </LocalizationProvider>
                }
                {type === 'timePicker' &&
                <LocalizationProvider dateAdapter={AdapterDayjs}  adapterLocale="en-gb">
                    <TimePicker
                        fullWidth
                        id={props.fieldName}
                        label={props.fieldName in props.formFields ? brandContext.textContent[props.formFields[props.fieldName].label] : ''}
                        defaultValue={dayjs(props.formik.values[props.fieldName])}
                        disableOpenPicker
                        onChange={(e) => {
                            props.formik.setFieldValue(props.fieldName, e);
                        }}
                        {...extendProps}

                    />
                </LocalizationProvider>
                }
                {type === 'fileUpload' &&
                    <FileUpload {...props}/>
                }
                {type === 'radioButton' &&
                <FormControl component="fieldset">
                    <FormLabel
                        component="legend">{props.fieldName in props.formFields ? brandContext.textContent[props.formFields[props.fieldName].label] : ''}</FormLabel>
                    <RadioGroup row={!props.formFields[props.fieldName].vertical}
                                aria-label={props.fieldName in props.formFields ? props.formFields[props.fieldName].label : ''}
                                name={props.fieldName}
                                id={props.fieldName}
                                value={props.formik.values[props.fieldName].toString()}
                                onChange={(e) => {
                                    // console.log(e.currentTarget.value);
                                    props.formik.setFieldValue(props.fieldName, e.currentTarget.value);
                                    if(typeof additionalOnChange === 'function'){
                                        additionalOnChange(e)
                                    }
                                }}
                    >
                        {props.formFields[props.fieldName].options.map((option, index) => {
                            const {
                                hide=false
                            } = option;
                            if(!hide){
                                return(
                                    <FormControlLabel 
                                        key={index} 
                                        value={option.value.toString()} 
                                        control={<Radio/>}
                                        label={option.componentLabel ? option.componentLabel : brandContext.textContent[option.label]}
                                    />
                                );
                            }
                            return null;
                        })}
                    </RadioGroup>
                </FormControl>
                }
                {type === 'checkbox' &&
                    <BasicCheckBox {...props}/>
                }
                {type === 'multiCheckbox' &&
                <FormGroup>
                    {props.formFields[props.fieldName].options.map((option, index) => {
                        const map = {};
                        for(let i=0; i<props.formik.initialValues[props.fieldName].length; i+=1){
                            map[props.formik.initialValues[props.fieldName][i]] = true;
                        }
                       return (
                       <FormControlLabel 
                            key={index}
                           
                            
                            control={<Checkbox defaultChecked={option.value  in map}/>}
                            onChange={(e) =>{
                                // console.log(props.formik.values[props.fieldName]);
                                
                                let items = props.formik.values[props.fieldName];
                                if(!Array.isArray(items)){
                                    items = [];
                                }
                                const valueToRemove = option.value;
                                const filteredItems = items.filter(item => item !== valueToRemove)
                                if(e.target.checked){
                                    filteredItems.push(option.value);
                                }                                
                                props.formik.setFieldValue(props.fieldName,filteredItems);
                                if(typeof additionalOnChange === 'function'){
                                    additionalOnChange(e)
                                }
                            }}
                            value={option.value}
                            label={option.componentLabel ? option.componentLabel : brandContext.textContent[option.label]}
                        />);
                        })}
                </FormGroup>
                }
                {type === 'multiCheckboxItem' &&

                       <>
                        <FormControlLabel  
                            control={<Checkbox defaultChecked={props.defaultChecked}/>}
                            onChange={(e) =>{
                                let items = props.formik.values[props.fieldName];
                                if(!Array.isArray(items)){
                                    items = [];
                                }
                                const valueToRemove = props.value;
                                const filteredItems = items.filter(item => item !== valueToRemove)
                                if(e.target.checked){
                                    filteredItems.push(props.value);
                                }                                

                                props.formik.setFieldValue(props.fieldName,filteredItems);
                                if(typeof additionalOnChange === 'function'){
                                    additionalOnChange(e)
                                }
                            }}
                            value={props.value}
                            {...extendProps}
                            label={props.componentLabel ? props.componentLabel : brandContext.textContent[props.label]}
                        />
                        </>
                }
            </>

        );
    }
    cWarn(`Field '${props.fieldName}' could not be found in fieldset.`);
    cLog(props.formFields);
    return null;
}