import {
    CssBaseline,
    
} from '@mui/material';
import React from 'react';



import BrandContextProvider from './contexts/BrandContext';

import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import DalProvider from './components/authentication/DalProvider';
import { store, persistor } from './redux/store';
import { LoadingBox } from './components/UniversalComponents';
import DalRouter from './components/DalRouter';
import { BrandedStyles, responsiveTheme } from './Theme';
import { ThemeProvider } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import { cLog } from './components/CustomLogger';
import ProfileContextProvider from './contexts/ProfileContext';


require('dotenv').config()



function App() {
    cLog('Version 2.1.1');
    return (
        <ThemeProvider theme={responsiveTheme}> 
            <HelmetProvider>
                <PersistGate loading={<LoadingBox />} persistor={persistor}></PersistGate>
                <ReduxProvider store={store}>
                        <BrandContextProvider>
                            <ProfileContextProvider>
                                <CssBaseline/>  
                                <DalProvider>
                                    <DalRouter/>
                                </DalProvider>
                            </ProfileContextProvider>
                        </BrandContextProvider>
                        <BrandedStyles/>
                </ReduxProvider>
            </HelmetProvider>
        </ThemeProvider>
    );
}

export default App;