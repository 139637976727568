import * as React from 'react';
import {useContext} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import throttle from 'lodash/throttle';
import axios from "../../utils/axios";

import { BrandContext } from '../../contexts/BrandContext';

export default function ComboBox(props) {

  const [value, setValue] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const brandContext = useContext(BrandContext);

  const fetch = React.useMemo(
      () =>
          throttle((request, callback) => {
            const apiPath = `${process.env.REACT_APP_API_URL}${props.formFields[props.fieldName].apiLookupPath}${request.input.replace('/',' ')}`;
            axios.get(apiPath,{
              timeout: 30000,
              withCredentials:true
            }).then(res => {
              callback(res.data);
            }).catch(err => {
              // console.log(err);
            });
          }, 200),
      [props],
  );

  React.useEffect(() => {
    let active = true;
    const allowBlankLookup = (props.formFields[props.fieldName].allowBlankLookup === true);

    if (inputValue === '' && allowBlankLookup === false) {

        setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({input: inputValue}, (results) => {

      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };


  }, [value, inputValue, fetch, props]);

    
  const {onChange:additionalOnChange} = props;
  
    return (
    <Autocomplete
        filterOptions={(x) => x}

        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          props.formik.setFieldValue(props.fieldName,newValue);
          if(typeof additionalOnChange === 'function'){
              additionalOnChange(newValue)
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        value={props.formik.values[props.fieldName]}
      disablePortal
        getOptionLabel = {option => props.formFields[props.fieldName].optionTemplate(option)}
      id="combo-box-demo"
      options={options}
      sx={{ width: '100%' }}
      renderInput={(params) => <TextField {...params} label={props.fieldName in props.formFields ? brandContext.textContent[props.formFields[props.fieldName].label] : ''} />}
    />
  );
}
