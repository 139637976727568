
import React, { useContext } from "react";
import {
    Checkbox,
    FormControlLabel,
    TextField
} from "@mui/material";

import { BrandContext } from "../../contexts/BrandContext";

import 'dayjs/locale/en-gb';
import { cLog } from "../CustomLogger";

export default function BasicCheckBox (props){

    const brandContext = useContext(BrandContext);

    const {
        textContent
    } = brandContext;

    const {
        fieldName,
        formFields,
        formik:{
            values,
            handleChange,
            touched,
            errors,
            initialValues
        },
        onChange:additionalOnChange
    } = props;

    const defaultField = {
        submitOnEnter: false
    };

    if(!(fieldName in formFields)){
        cLog(`${fieldName} not found in fields.`);
        return null;
    }

    const runningField = {defaultField, ...formFields[fieldName]};

    const {
        useCms=true,
        label
    } = runningField;

    const extendProps = {...props};
    const filterFields = ['formik','formFields','fieldName','onChange'];
    filterFields.forEach(key => delete extendProps[key]);

    let fieldlabel = label;
    if(useCms){
        fieldlabel = textContent[runningField.label];
    }

    return  (
        <FormControlLabel
            id={fieldName}
            value={values[props.fieldName]}
            control={<Checkbox defaultChecked={initialValues[props.fieldName]}/>}
            onChange={(e) =>{
                props.formik.setFieldValue(props.fieldName,e.target.checked);
                if(typeof additionalOnChange === 'function'){
                    additionalOnChange(e)
                }
            }}
            label={fieldlabel}
            {...extendProps}
        />
    );
}